import { Component, ComponentFactoryResolver, Input, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { Const } from 'src/app/core/enums/const.enums';
import { ApplicationService } from 'src/app/core/service/application.service';
import { ModalService } from 'src/app/core/service/modal.service';
import { SpinnerService } from 'src/app/core/service/spinner.service';

import { SsoService } from 'src/app/core/service/app.service';
import jwt_decode from 'jwt-decode';
import { AlertService } from 'src/app/core/service/alert.service';
import { TabService } from 'src/app/core/service/tab.service';
import { HeaderTransmitService } from 'src/app/core/service/transmit.service';
import { TransmitUtils } from 'src/app/core/service/transmit-utils';
// import { WindowComponent } from 'src/app/components/window/window.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { assetURL, environment } from 'src/environments/environment';


@Component({
  selector: 'app-transaction-view',
  templateUrl: './transaction-view.component.html',
  styleUrls: ['./transaction-view.component.css']
})
export class TransactionViewComponent {
  public transactionList: any = [];
  public transactionDetails: any;
  public accountType: string = '';
  public pageNumber: number = 0;
  public btnLabel: string = '';
  public errorMsg: string = '';
  public accountDetails: any;
  public enableNextBtn: boolean = false;
  public enablePrevBtn: boolean = false;
  public showNoDataError: boolean = false;
  public resizedWindow: boolean = false;

  @ViewChild('modal') tabModal!: TemplateRef<any>;
  @ViewChild('container', { read: ViewContainerRef }) container!: ViewContainerRef;
  public samlUrl: string = '';
  public samlInfo: string = '';
  public tabName: string = '';
  public universalId: string = '';
  public modalDisplayName: string = '';
  public modalIsRegistered: boolean = false;
  private legacyEmail: string = '';
  private personaDetailsExtesnionJson: {} | undefined;
  private openedTab: Window | null = null

  constructor(
    private appService: ApplicationService,
    private modalService: ModalService,
    private spinnerService: SpinnerService,
    private appService1: SsoService,
    private alertService: AlertService,
    private resolver: ComponentFactoryResolver,
    private tabService: TabService,
    private transmitService: HeaderTransmitService,
    private transmitUtils: TransmitUtils,
    private ngbModalService: NgbModal

  ) {
    this.resizedWindow = this.modalService.setWindow();
  }

  ngOnInit(): void {
    const getAccoundtData = this.modalService.getAccountValues()
    this.accountDetails = getAccoundtData.accountDetails
    this.universalId = sessionStorage.getItem('universalId') ?? '';
    this.accountType = getAccoundtData.accountType
    this.getViewTransactionList();
    this.getLabel();
  }

  ngAfterViewInit(): void {
    this.transmitService.initializeTransmit(environment.transmitUrl);
  }

  getDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth();
    const date = today.getDate();

    const _month = (month + 1).toString().padStart(2, '0');
    const _date = date.toString().padStart(2, '0');
    return `${year}-${_month}-${_date}`;
  }

  public getViewTransactionList: any = () => {

    let url = this.accountType === 'loans' ? 'v1/transactions/loan' : 'v1/transactions/deposit'
    const reqBody = this.createRequestBody();

    this.appService.postFunction(`${url}`, reqBody, `${this.accountDetails['ssoId']}`)
      .subscribe((response: any) => {
        this.spinnerService.hideSpinner();
        this.handleResponse(response)
      });
  }

  public createRequestBody () {
    const reqBody: any = {
      accountNumber: this.accountDetails.accountNumber,
      // startDate: '2023-11-14',
      // endDate: '2023-21-22',
      pageNumber: this.pageNumber,
      pageLimit: 10
    };

    if (this.accountType === "deposits") {
      reqBody['routingNumber'] = this.accountDetails.routingNumber;
      reqBody['transactionType'] = "ALL_TRANSACTIONS";
      reqBody['accountType'] = this.accountDetails.accountType;
    }

    return reqBody
  }

  public handleResponse(response: any) {
    if(response.status === 200 || response.status === 204) {
      this.processSuccessfulResponse(response)
    } else {
      this.showNoDataError = false
    }
    this.transactionDetails = this.accountDetails
  }

  public processSuccessfulResponse(response: any){
    if (response?.data?.transaction && Array.isArray(response?.data?.transaction)) {
      const responseData = response.data;
      this.enablePrevBtn = this.pageNumber > 0
      this.enableNextBtn = `${(responseData['moreRecords'])}`.toLowerCase() === 'yes';
      this.transactionList = response?.data?.transaction;
      this.showNoDataError = this.transactionList.length === 0;
    } else {
      this.showNoDataError = true
    }
  }

  openViewMore() {
    const getSsoIdArr = this.appService.getSsoIdArrValue()
    const selectSsoId = getSsoIdArr.find((ssoIdData: any) => ssoIdData['ssoId'] === this.accountDetails['ssoId'])

    // const openAOEvent = new CustomEvent("open-access-optima", {
    //   detail: { from: "btl-application", ssoIdData: selectSsoId }
    // });
    // dispatchEvent(openAOEvent);
    this.login(selectSsoId)
    this.destroyData()
  }

  login(item:any) {
    let userId = item.userId;
    let compId = item.companyId;
    let appName = item.applicationName === 'cashflow' ? item.applicationName.toUpperCase() : item.applicationName;
    var appSpecificData = this.appService1.getConfigAppsData(appName);
    let displayName = appSpecificData?.displayName;
    let isRegistered = appSpecificData?.isRegistered;
    let accountLogin = item.ssoId;
    let personalDetailExtension = item.personaDetailsExtension;
    let webAppId = this.appService1.getTransmitJourney(item.applicationName);
    console.log("chckng if AO btn event is triggere",item)
    if (personalDetailExtension === undefined) {
      this.legacyEmail = '';
    } else {
      this.legacyEmail = personalDetailExtension.legacyEmailAddress;
      this.personaDetailsExtesnionJson = personalDetailExtension.personaDetailsExtesnionJson;
      if ((typeof this.personaDetailsExtesnionJson) === 'string') {
        this.personaDetailsExtesnionJson = JSON.parse(personalDetailExtension.personaDetailsExtesnionJson);
      }
    }
    this.alertService.hide();
    this.spinnerService.clearText();
    // TODO actual login!
    this.appService1.updateLastLogin(accountLogin, this.universalId);
    // Last Login Details Update to DB

    const samlInfo: any = this.appService1.getSamlInfo(appName);
    this.samlUrl = samlInfo.samlActionUrl;
    this.tabName = appName;
    this.samlInfo = 'data';     // TODO: change it!
    const isOpen = this.tabService.isAppTabOpen(appName);

    if (isOpen) {
      // open dialog message to close tab
      this.openMessageDialog(displayName, isRegistered);
    } else {

      const preprodValue = sessionStorage.getItem('preprod');
      let journeyName = 'ping_dropoff';

      // seperate route for non SAML flow
      if (appSpecificData?.usesHeaderInjection) {
        const url = new URL(this.appService1.getConfigAppsData(appName)?.baseUrls?.login)
        console.log(url)
        const hintValue = userId + "_" + (compId ? compId : '')
        console.log(hintValue);
        let params = new URLSearchParams;
        params.append('hint', hintValue);
        const redirectUrl = `${url}?${params.toString()}`;
        var newTab = window.open(this.appService1.getConfigAppsData(appName)?.baseUrls?.logout)
        setTimeout(function () {
          if (newTab && !newTab.closed) {
            newTab.close();
            window.open(redirectUrl)
          }
        }, 2000);

      } else if (appSpecificData?.isDeeplinkEnabled) {
        this.appService1.getDeepLinkURL(userId, compId, appName, appSpecificData).then(deeplinkUrl => {
          this.openTab(deeplinkUrl, appName);
        });
      } else {
        // route for SAML flow
        this.transmitService.pingDropOffTransmitInvokePolicy(userId, compId, appName, webAppId, accountLogin, journeyName, this.universalId, this.legacyEmail, preprodValue, this.personaDetailsExtesnionJson).then((res: any) => {
          console.log('token', res._token);
          const tokenInfo = this.getDecodedAccessToken(res._token);

          if (tokenInfo === undefined || tokenInfo === null || tokenInfo === '') {
            this.alertService.error(this.transmitUtils.getErrorMessage('serviceUnavailableTryAgain'));
            return;
          }

          let ping_ref_id = tokenInfo['ping_ref_id'];
          let ping_login_url = tokenInfo['ping_sso_url'];


          if (ping_login_url === undefined || ping_login_url === null || ping_login_url === '') {
            this.alertService.error(this.transmitUtils.getErrorMessage('serviceUnavailableTryAgain'));
            return;
          }

          this.openTab(ping_login_url, appName);
        }).catch((error: any) => {
          const value = {error: error, item: item}
          const errorEvt = new CustomEvent("error-access-optima", {
          detail: { from: "btl-application", value }});
          dispatchEvent(errorEvt);
        });
      }
    }
  }

  private openTab(ping_login_url: any, appName: any) {
    if (this.openedTab == null || this.openedTab.closed) {
      this.openedTab = window.open(ping_login_url);
      const appTabComponent: { [key: string]: string; } = {};
      appTabComponent['appName'] = this.tabName;
      appTabComponent['SamlUrl'] = ping_login_url;
      this.tabService.saveAppComponentTab(appName, appTabComponent);
      this.tabService.saveAppTab(appName, this.openedTab);
    }
  }

  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    } catch (Error) {
      return null;
    }
  }

  getNested(obj:any, ...args:any) {
    return args.reduce((obj:any, level:any) => obj && obj[level], obj);
  }

  openMessageDialog(displayName:any, isRegistered:any) {
    this.modalDisplayName = displayName;
    this.modalIsRegistered = isRegistered;
    this.ngbModalService.open(this.tabModal, { size: 'sm', centered: true });
  }

  public destroyData: any = () => {
    this.transactionList = [];
  }

  handleTablePagination(actionType: string) {
    if (actionType === 'next') {
      this.pageNumber = this.pageNumber + 1
    }
    if (actionType === 'previous' && this.pageNumber > 0) {
      this.pageNumber = this.pageNumber - 1
    }
    this.getViewTransactionList();
  }

  public getLabel() {
    this.errorMsg = `${this.accountDetails['ssoId']}`.includes('MMGPS') ? Const.aoErrorMsg : Const.cfeErrorMsg;
    this.btnLabel = `${this.accountDetails['ssoId']}`.includes('MMGPS') ? 'accessOptima' : 'Cash Flow Essentials';
  }
}
